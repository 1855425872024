import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: 'black',
      // default: '#121212',
    },
  },
  typography: {
    fontSize: 17,
  },
});
