import {
  AppBar,
  CssBaseline,
  Divider,
  Grid,
  Hidden,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';

import Img from 'gatsby-image';
import { Link } from 'gatsby';
import React from 'react';
import { isString } from 'lodash';
import theme from '../theme';

const Content = ({ fullWidth, children }) => {
  const SPACING = 16;
  return (
    <div
      style={{
        padding: fullWidth ? 0 : `0px ${SPACING}px`,
        overflow: 'hidden',
      }}
    >
      <Grid container justify={'center'} spacing={fullWidth ? 0 : SPACING / 8}>
        <Grid
          item
          xs={12}
          sm={fullWidth ? 12 : 10}
          md={fullWidth ? 12 : 6}
          lg={fullWidth ? 12 : 6}
          xl={fullWidth ? 12 : 4}
          style={{
            width: '100%',
            maxWidth: fullWidth ? '100%' : '620px',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

const AppHeader = ({ children }) => (
  <AppBar
    position={'static'}
    elevation={0}
    style={{
      position: 'absolute',
      backgroundColor: 'unset',
    }}
  >
    <Toolbar>
      <Hidden xsDown>
        <Typography
          variant={'h5'}
          style={{
            fontWeight: 'bold',
          }}
        >
          {children}
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography
          variant={'h6'}
          style={{
            fontWeight: 'bold',
          }}
        >
          {children}
        </Typography>
      </Hidden>
    </Toolbar>
  </AppBar>
);

const Layout = (props) => {
  const { location, title, children, cover, banner, fullWidth = false } = props;
  const rootPath = `${__PATH_PREFIX__}/`;

  const header = (
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
      to={`/`}
    >
      {title}
    </Link>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      {!!cover ? (
        isString(cover) ? (
          <img style={{ width: '100%' }} src={cover} />
        ) : (
          <Img sizes={cover.childImageSharp.sizes} />
        )
      ) : null}
      <AppHeader>{header}</AppHeader>
      <Divider />
      <Content fullWidth={fullWidth}>
        {children}
        <br />
        <br />
        <footer style={{ padding: '8px', textAlign: 'center' }}>
          <Typography color={'textSecondary'} variant={'body2'}>
            © {new Date().getFullYear()}, Dmytro Rohov{' '}
          </Typography>
        </footer>
      </Content>
      <br />
    </MuiThemeProvider>
  );
};

export default Layout;
